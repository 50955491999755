<template>
<div v-if="journalItems">
  <div v-for="(item, key) of journalItems" :key="key" :class="'journal-overview page' + item.paged">
    <div v-html="item.html"></div>
  </div>

  <div v-if="page < maxPages" class="text-center pt-5 pt-lg-0" data-maki="scroll-in">
    <p v-if="!loading" class="link--line text-secondary">
      <a href="#" @click.prevent="loadMoreItems">Mehr laden</a>
    </p>
    <p v-else-if="loading" class="loading">
    </p>
  </div>
</div>
</template>

<script>
export default {
  name: "JournalOverview",

  data() {
    return {
      journalItems: [],
      journalFeedUrl: "/wp-json/v1/journal/news-items",
      page: 1,
      maxPages: 1,
      loading: false
    }
  },

  mounted() {
    this.loadItems();
    this.journalItems = [];
  },

  methods: {
    loadItems() {
      const vue = this;
      vue.loading = true;

      window.depsloader(["axios", "maki-js"], (axios, makiJs) => {
        let urlToSend = this.journalFeedUrl + "?paged=" + this.page;

        if (this.$root.taxonomy) {
          urlToSend += "&taxonomy=" + this.$root.taxonomy;
        }

        axios.get(urlToSend)
        .then(function (response) {
          if (response.status != 200) {
            return;
          }

          const data = JSON.parse(response.data);
          vue.maxPages = data.maxPages;

          vue.journalItems = vue.journalItems.concat(data.items);
        })
        .finally(() => {
          vue.loading = false;

          makiJs({
            treshold: 0,
            rootMargin: '-25% 0% -25% 0%'
          });

          if (vue.page > 1) {
            window.setTimeout(() => {
              const firstElm = document.querySelector(".page" + vue.page);
              firstElm.scrollIntoView();
            }, 500);
          }
        })
      });
    },

    loadMoreItems() {
      this.page += 1;
      this.loadItems();
    }
  }
}
</script>

<style>
.list-item {
  display: inline-block;
  margin-right: 10px;
}
.list-enter-active, .list-leave-active {
  transition: all 1s;
}
.list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(30px);
}
</style>
