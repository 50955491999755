/*
Diese Datei landet in der app.js, d.h. keine vuetify-Komponenten laden. Dafür den depsloader verwenden.
*/
import Vue from "vue";
import ContactFormApp from "./ContactFormApp.vue";

const elements = document.querySelectorAll(".vue-contact");
if (elements) {
  window.depsloader(["vuetify", "vuetify-form", "vue-i18n"], function(vuetify, vuetifyForm, i18n) {
    for (const element of elements) {
      new Vue({
        el: element,
        template: element,
        vuetify,
        i18n,
        components: {
          ContactFormApp
        }
      });
    }
  });
}
