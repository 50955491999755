const elements = document.querySelectorAll(".grid");
if (elements) {
  window.depsloader(["macy"], function(macy) {
    for (const elm of elements) {
      macy.macy({
        container: elm,
        columns: 3,
        margin: {
          x: 64,
          y: 24
        },
        breakAt: {
          992: {
            margin: {
              x: 20,
              y: 10,
            },
            columns: 2
          },

          576: {
            margin: {
              x: 0,
              y: 0,
            },
            columns: 1
          }
        }
      });
    }
  });
}
