const elements = document.querySelectorAll(".scroll-reveal");
if (elements) {
  window.depsloader(["maki-js"], function(makiJs) {
    for (const elm of elements) {
      elm.dataset.maki = "scroll-in";
    }

    makiJs({
      treshold: 0,
      rootMargin: '-25% 0% -25% 0%'
    });
  });
}
