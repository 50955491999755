<template>
<div>
  <div v-if="!contactFormName || contactFormName == 'default'">
    <div class="row row-small">
      <div class="col-12">
        <v-text-field :label="label('firstname')" v-model.trim="contact.firstname" :error="fieldError('firstname')" height="65" single-line hide-details="auto"></v-text-field>
      </div>

      <div class="col-12">
        <v-text-field :label="label('lastname')" v-model.trim="contact.lastname" :error="fieldError('lastname')" height="65" single-line hide-details="auto"></v-text-field>
      </div>
    </div>

    <div class="row row-small" v-if="enableAddress">
      <div class="col-24 col-lg-11">
        <v-text-field :label="label('street')" v-model="contact.street" :error="fieldError('street')" hide-details="auto"></v-text-field>
      </div>

      <div class="col-24 col-lg-13">
          <div class="row row-small">
            <div class="col-24 col-lg-12">
              <v-text-field :label="label('zip')" v-model.trim="contact.zip" :error="fieldError('zip')" hide-details="auto"></v-text-field>
            </div>

            <div class="col-24 col-lg-12">
              <v-text-field :label="label('city')" v-model.trim="contact.city" :error="fieldError('city')" hide-details="auto"></v-text-field>
            </div>
          </div>
      </div>
    </div>

    <div class="row row-small">
      <div class="col-12">
        <v-text-field :label="label('email')" v-model.trim="contact.email" :error="fieldError('email')" height="65" single-line hide-details="auto"></v-text-field>
      </div>

      <div class="col-12">
        <v-text-field :label="label('contactReason')" v-model.trim="contact.contactReason" :error="fieldError('contactReason')" height="65" single-line hide-details="auto"></v-text-field>
      </div>

      <div class="col-12" v-if="enableMessage">
        <v-textarea rows="3" :label="label('message')" :error="fieldError('message')" v-model.trim="contact.message"  single-line hide-details="auto"></v-textarea>
      </div>
    </div>
  </div>
  <div v-else-if="contactFormName == 'form-footer'">
    <v-row class="row row-small">
      <v-col class="col-12 col-lg-6">
        <v-text-field :label="label('firstname')" v-model.trim="contact.firstname" :error="fieldError('firstname')" single-line height="65" hide-details="auto"></v-text-field>
      </v-col>

      <v-col class="col-12 col-lg-6">
        <v-text-field :label="label('lastname')" v-model.trim="contact.lastname" :error="fieldError('lastname')" single-line height="65" hide-details="auto"></v-text-field>
      </v-col>
    </v-row>

    <v-row class="row row-small">
      <v-col class="col-12 col-lg-6">
        <v-text-field :label="label('email')" v-model.trim="contact.email" :error="fieldError('email')" single-line height="65" hide-details="auto"></v-text-field>
      </v-col>
    </v-row>
  </div>
  <v-row v-else-if="contactFormName == 'form-gift'">
    <v-col class="col-12 col-lg-6">
      <v-text-field :label="label('firstname')" v-model.trim="contact.firstname" :error="fieldError('firstname')" single-line height="65" hide-details="auto"></v-text-field>

      <v-text-field :label="label('lastname')" v-model.trim="contact.lastname" :error="fieldError('lastname')" single-line height="65" hide-details="auto"></v-text-field>

      <v-text-field :label="label('streetNumber')" v-model.trim="contact.streetNumber" :error="fieldError('streetNumber')" single-line height="65" hide-details="auto"></v-text-field>

      <v-text-field :label="label('zipCity')" v-model.trim="contact.zipCity" :error="fieldError('zipCity')" single-line height="65" hide-details="auto"></v-text-field>
    </v-col>

    <v-col class="col-12 col-lg-6">
      <v-text-field :label="label('country')" v-model.trim="contact.country" :error="fieldError('country')" single-line height="65" hide-details="auto"></v-text-field>

      <v-text-field :label="label('phone')" v-model.trim="contact.phone" :error="fieldError('phone')" single-line height="65" hide-details="auto"></v-text-field>

      <v-text-field :label="label('email')" v-model.trim="contact.email" :error="fieldError('email')" single-line height="65" hide-details="auto"></v-text-field>

      <v-text-field :label="label('giftAmount')" v-model.trim="contact.giftAmount" :error="fieldError('giftAmount')" single-line height="65" hide-details="auto"></v-text-field>
    </v-col>
  </v-row>
  <v-row v-else-if="contactFormName == 'form-booking'">
    <v-col class="col-12 col-lg-6">
      <v-text-field :label="label('firstname')" v-model.trim="contact.firstname" :error="fieldError('firstname')" single-line height="65" hide-details="auto"></v-text-field>

      <v-text-field :label="label('lastname')" v-model.trim="contact.lastname" :error="fieldError('lastname')" single-line height="65" hide-details="auto"></v-text-field>

      <v-text-field :label="label('streetNumber')" v-model.trim="contact.streetNumber" :error="fieldError('streetNumber')" single-line height="65" hide-details="auto"></v-text-field>

      <v-text-field :label="label('zipCity')" v-model.trim="contact.zipCity" :error="fieldError('zipCity')" single-line height="65" hide-details="auto"></v-text-field>

      <v-text-field :label="label('country')" v-model.trim="contact.country" :error="fieldError('country')" single-line height="65" hide-details="auto"></v-text-field>
    </v-col>

    <v-col class="col-12 col-lg-6">
      <v-text-field :label="label('phone')" v-model.trim="contact.phone" :error="fieldError('phone')" single-line height="65" hide-details="auto"></v-text-field>

      <v-text-field :label="label('email')" v-model.trim="contact.email" :error="fieldError('email')" single-line height="65" hide-details="auto"></v-text-field>

      <v-textarea :label="label('comment')" v-model.trim="contact.comment" :error="fieldError('comment')" rows="3" single-line height="130" hide-details="auto"></v-textarea>
    </v-col>
  </v-row>

  <v-row v-if="contactFormName == 'form-gift'">
    <v-col cols="12" lg="6">
      <v-checkbox hide-details :ripple="false" v-model="contact.sendPerPost" :error="fieldError('sendPerPost')">
        <template v-slot:label>
          <div class="text-small mb-0" v-html="$t('Gutschein in Papierformat per Post (zzgl. 5,00 € Versandkosten)')"></div>
        </template>
      </v-checkbox>

      <v-checkbox hide-details :ripple="false" v-model="contact.sendPerEmail" :error="fieldError('sendPerEmail')">
        <template v-slot:label>
          <div class="text-small mb-0" v-html="$t('Gutschein als PDF-Datei per E-Mail')"></div>
        </template>
      </v-checkbox>
    </v-col>

    <v-col cols="12" lg="6">
      <v-checkbox hide-details :ripple="false" v-model="dataPrivacy" :error="fieldError('dataPrivacy')">
        <template v-slot:label>
          <div class="text-small mb-0" v-html="$t('dataPrivacyShort', { link: privacyUrlLink })"></div>
        </template>
      </v-checkbox>

      <v-checkbox hide-details :ripple="false" v-model="contact.conditions" :error="fieldError('conditions')">
        <template v-slot:label>
          <div class="text-small mb-0" v-html="$t('dataPrivacyShort', { link: conditionsUrlLink })"></div>
        </template>
      </v-checkbox>
    </v-col>
  </v-row>
  <div v-else>
    <v-checkbox hide-details :ripple="false" v-model="dataPrivacy" :error="fieldError('dataPrivacy')">
      <template v-slot:label>
        <div class="text-small mb-0" v-html="$t('dataPrivacyShort', { link: privacyUrlLink })"></div>
      </template>
    </v-checkbox>

    <v-checkbox hide-details :ripple="false" v-model="contact.conditions" :error="fieldError('conditions')">
      <template v-slot:label>
        <div class="text-small mb-0" v-html="$t('dataPrivacyShort', { link: conditionsUrlLink })"></div>
      </template>
    </v-checkbox>
  </div>
</div>
</template>

<script>
export default {
  name: "ContactForm",

  props: {
    privacyUrl: {
      type: String,
      default: null
    },
    conditionsUrl: {
      type: String,
      default: null
    },
    requiredFields: {
      type: Array,
      default: () => {
        return [];
      }
    },
    enableMessage: {
      type: Boolean,
      default: false
    },
    enableAddress: {
      type: Boolean,
      default: false
    },
    defaultMessage: {
      type: String,
      default: null
    }
  },

  data() {
    return {
      salutationItems: [
        { text: "Frau", value: "Frau" },
        { text: "Herr", value: "Herr" },
        { text: "Divers", value: "Divers" }
      ],
      busy: false,
      contact: {
        email: null,
        firstname: null,
        lastname: null,
        message: null,
        phone: null,
        salutation: null,
        subject: null,
        referrer: null,
        sendPerPost: false,
        sendPerEmail: false
      },
      dataPrivacy: false,
      conditions: false
    };
  },

  computed: {
    privacyUrlLink() {
      return "<a href=\"" + this.privacyUrl + "\" target=\"_blank\" onclick=\"event.stopPropagation()\">" + this.$t("Datenschutzerklärung") + "</a>";
    },

    conditionsUrlLink() {
      return "<a href=\"" + this.conditionsUrl + "\" target=\"_blank\" onclick=\"event.stopPropagation()\">" + this.$t("AGB") + "</a>";
    },

    contactForm() {
      return this;
    },

    contactFormName() {
      return this.$root.ContactFormApp.$props.name;
    }
  },

  mounted() {
    const params = new URLSearchParams(window.location.search);

    if (params.get("subject")) {
      this.contact.subject = params.get("subject");
    }
  },

  methods: {
    validate() {
      this.busy = true;

      let valid = this.dataPrivacy;

      this.requiredFields.forEach(name => {
        if (!this.fieldValid(name)) {
          valid = false;
        }
      });

      return valid;
    },

    label(field) {
      let text = ((field) => {
        switch (field) {
          case "salutation":
            return this.$t("Anrede");

          case "firstname":
            return this.$t("Vorname");

          case "lastname":
            return this.$t("Nachname");

          case "birthday":
            return this.$t("Geburtsdatum");

          case "phone":
            return this.$t("Telefon");

          case "email":
            return this.$t("E-Mail");

          case "street":
            return this.$t("Straße");

          case "number":
            return this.$t("Hausnummer");

          case "zip":
            return this.$t("PLZ");

          case "city":
            return this.$t("Ort");

          case "contactReason":
            return this.$t("contactReason");

          case "message":
            return this.$t("Ihre Nachricht");

          case "streetNumber":
            return this.$t("Strasse und Hausnummer");

          case "zipCity":
            return this.$t("PLZ/Stadt");

          case "country":
            return this.$t("Land");

          case "giftAmount":
            return this.$t("Gutscheinwert");

          case "giftRecipient":
            return this.$t("Gutscheinempfänger");

          case "giftText":
            return this.$t("Text für Gutschein");

          case "comment":
            return this.$t("Bemerkung");
        }
      })(field);

      if (this.requiredFields.indexOf(field) > -1) {
        text += "*";
      }

      return text;
    },

    fieldValid(field) {
      if (!this.contact[field]) {
        return false;
      }

      if (field == "email") {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return pattern.test(this.contact[field]);
      }

      return true;
    },

    fieldError(field) {
      if (!this.busy) {
        return false;
      }

      if (this.requiredFields.indexOf(field) > -1) {
        if (!this.fieldValid(field)) {
          return true;
        }
      }

      if (field == "dataPrivacy" && !this.dataPrivacy) {
        return true;
      }

      return false;
    }
  }
};
</script>
