import JournalOverview from "./JournalOverview.vue";

const element = document.querySelector("#vue-journal");
if (element) {
  window.depsloader(["vue", "vue-i18n"], function(Vue, vueI18n) {
    new Vue({
      el: element,
      template: element,

      components: {
        JournalOverview
      },

      computed: {
        taxonomy() {
          if (element.dataset.taxonomy) {
            return element.dataset.taxonomy;
          }

          return null;
        }
      },

      i18n: vueI18n
    });
  });
} else {
  console.log("journal: #vue-journal not found");
}
