import yeti from "./yeti"; // eslint-disable-line
require("./sass/screenwork.scss");

import "./sass/fontawesome/fas.font.js";
import "./sass/fontawesome/far.font.js";
import "./sass/fontawesome/fal.font.js";
import "./sass/fontawesome/fab.font.js";
import "./sass/fontawesome/fontawesome.scss";

const requirePO = require.context("./lang", false, /\.po$/);
requirePO.keys().forEach(requirePO);

import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import preset from "./preset";
import depsloader from "./depsloader";

Vue.config.productionTip = false;

Vue.prototype.$yeti = yeti;
Vue.prototype.$yeti.setupTracking();

Vue.use(Vuetify);

const vuetify = new Vuetify({
  preset,
  icons: {
    iconfont: "fa",
  },
});

window.depsloader = function (name, cb) {
  if (Array.isArray(name)) {
    const list = [];

    name.forEach((dep) => {
      if (dep == "vue") {
        list.push(Promise.resolve(Vue));
      } else if (dep == "vuetify") {
        list.push(Promise.resolve(vuetify));
      } else {
        list.push(depsloader.load(dep));
      }
    });

    Promise.all(list)
      .then((values) => {
        cb.apply(this, values);
      })
      .catch((err) => {
        console.log(err);
      });
  } else {
    if (name == "vue") {
      cb(Vue);
    } else if (name == "vuetify") {
      cb(vuetify);
    } else {
      depsloader.load(name).then((res) => {
        cb(res);
      });
    }
  }
};

// load components

// require("./components/cookie-notice");
require("./components/contact");
require("./components/maki-js");
require("./components/google-maps");
require("./components/journal-overview");
require("./components/bricksjs");
